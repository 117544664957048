@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap");

* {
  color: #393c41;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}

h1 {
  font-size: 40px;
  font-weight: bold;
}

p {
  font-size: 16px;
}

a {
  text-decoration: none;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

.card-image {
  object-fit: cover;
  height: 200px;
  width: 18rem;
}

.card-blog {
  margin: 10px;
}

.blog-main-section {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.blog-heading {
  text-align: center;
  margin-top: 2rem;
  line-height: 1.6;
}

.blog-sub-heading {
  text-align: center;
  line-height: 1.6;
  padding-bottom: 2rem;
  font-family: 'Montserrat', sans-serif;
  width: 60%;
  margin: auto;
  font-weight: 100;
}

.blog-image {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

.blog-back {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog-back-margin {
  margin-top: 40px;
}

.blog-gap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  text-align: center;
}

.blogger-image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 100%;
}

.blogger-image-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blogger-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blogger-name {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align:center;
  font-weight: 100;
}

.blog-description {
  line-height: 1.6;
  font-family: 'Montserrat', sans-serif;
}

.topic-heading {
  line-height: 1.6;
  font-weight: 700;
  margin-top: 10px;
}

.blog-explanation {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

}

@media screen and (max-width: 768px) {

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }  

  h3 {
    font-size: 18px;
  }  
  h4 {
    font-size: 16px;
  }  
  h5 {
    font-size: 14px;
  } 
  h6{
    font-size: 12px;
  } 

  p {
    font-size: 12px;
  }
  .blog-explanation {
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .blog-image {
    height: 150px;
    width: 100%;
  }

  .blog-back {
    margin-top: 10px;
  }

  .blog-gap {
    margin-bottom: 0px;
  }
  
}


.topic-subheading {
  line-height: 1.6;
  font-family: 'Montserrat';
  font-weight: 500;
}


.topic-image {
  object-fit: cover;
  height: 500px;
  width: 100%;
  margin-left: 5rem;
  margin-right: 5rem;
}

.topic-images {
  object-fit: contain;
  max-height:500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.topic-iframe {
  object-fit: contain;
  max-height:90vh;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.image-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.click {
  cursor: pointer;
}

.grey {
  color: #bbb;
}

.link {
  color: dodgerblue;
}